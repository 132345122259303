<template>
  <PagePreloader />
  <teleport to="body">
    <PleaseWaitPreloader v-if="isRequesting" />
  </teleport>
  <StayWithUs v-if="flow !== 'mainsite'" />
  <HeaderBlock v-if="flow !== 'mainsite'" />
  <ReloadPrompt />
  <router-view :key="$route.path" />
  <ProjectVersion v-if="flow !== 'mainsite'" />
</template>
<script setup>
import { onMounted } from 'vue'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'

import HeaderBlock from '@/blocks/HeaderBlock.vue'
import ReloadPrompt from '@/ReloadPrompt.vue'
import PagePreloader from '@/components/PagePreloader.vue'
import ProjectVersion from '@/components/ProjectVersion.vue'
import StayWithUs from '@/components/StayWithUs.vue'
import PleaseWaitPreloader from '@/components/PleaseWaitPreloader.vue'

const mainStore = useMainStore()

const { flow, isRequesting } = storeToRefs(mainStore)
onMounted(() => {})
</script>
