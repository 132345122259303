import { Centrifuge } from 'centrifuge'

export default {
  connect: (eventName, subChannel, token, action) => {
    // Use WebSocket transport endpoint.
    const centrifuge = new Centrifuge(import.meta.env.VITE_CENTRIFUGO_BASE_URL, {
      token: token
    })

    // Allocate Subscription to a channel.
    const sub = centrifuge.newSubscription(subChannel)

    // React on `subChannel` channel real-time publications.
    sub.on('publication', function (ctx) {
      if (ctx.channel === subChannel && ctx.data.event === eventName) {
        action(ctx.data)
      }
    })

    // Trigger subscribe process.
    sub.subscribe()

    // Trigger actual connection establishment.
    centrifuge.connect()

    return centrifuge
  }
}
