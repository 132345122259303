export default {
  getKeys: (keys) => {
    if (typeof keys === 'object') {
      let storageInstance = localStorage

      return keys.reduce((a, v) => ({ ...a, [v]: storageInstance.getItem(v) }), {})
    }

    return new Error('Expect array input keys')
  },
  fillOutKeys: (data) => {
    for (const [key] of Object.entries(data)) {
      data[key] = localStorage.getItem(key)
    }
    return data
  },
  getItem: (key) => {
    return localStorage.getItem(key)
  },
  setItem: (key, value) => {
    return localStorage.setItem(key, value)
  },
  removeItem: (key) => {
    return localStorage.removeItem(key)
  }
}
