<template>
  <div class="px-4 sm:px-0">
    <router-link type="button" class="go_back_button group" :to="backButtonUrl">
      <ArrowLeftIcon />
      <span class="go_back_button__text">{{ backButtonText }}</span>
    </router-link>
  </div>
  <div class="flex flex-col">
    <h1 class="title mb-[38px] px-4 sm:mb-[58px] sm:px-0 md:text-[80px]/[110%]">Select Vehicle</h1>
    <SummaryInfo
      classnames="summary_title_button_wrapper form_to_edit lg:mt-5 md:items-start"
      title="Ride Summary"
      disabled="false"
    />
  </div>
  <div v-if="isHourly === true" class="vehicle_form">
    <p class="vehicle_form__text">
      If the above Kms are not enough please click on the 2 below options
    </p>
    <div class="flex flex-row justify-start">
      <button
        class="button mr-4"
        :class="{
          not_active: activeFormName === 'AdditionalKmsForm' || activeFormName === 'notActive'
        }"
        @click="openConsultingForm()"
      >
        I need your consulting
      </button>
      <button
        v-if="!orderData.extra_kms"
        class="button"
        :class="{
          not_active: activeFormName === 'ConsultingForm' || activeFormName === 'notActive'
        }"
        @click="openAdditionalKmsForm()"
      >
        I know amount of Kms to add
      </button>
    </div>
    <div
      class="mt-4 hidden flex-col justify-start"
      :class="{ 'active !flex': activeFormName === 'ConsultingForm' }"
    >
      <p class="consulting_form__text">
        In order to properly estimate and quote your service, kindly let us have the specifics of
        what you require such as Destinations or Sights where you will stop, and if the Drop Off
        Location is different from that of the Pick Up. Number of Passengers, or Preferred Vehicle
        Type, and Pick up Time.
      </p>
      <Form
        @submit="consultingSubmit"
        class="form mt-4 flex flex-row justify-start gap-6"
        :validation-schema="consultingSchema"
        v-slot="{ errors, isSubmitting }"
      >
        <Field
          type="text"
          class="input vehicle_form__input"
          :class="errors.consultingMessage ? 'error' : ''"
          name="consultingMessage"
          v-model="consultingMessage"
          placeholder="Type here..."
        />
        <button
          type="submit"
          :disabled="isSubmitting"
          class="next_step_button vehicle_form__button"
        >
          Submit
        </button>
      </Form>
    </div>
    <div
      class="mt-4 hidden flex-col justify-start"
      :class="{ 'active !flex': activeFormName === 'AdditionalKmsForm' && !orderData.extra_kms }"
    >
      <Form
        @submit="additionalSubmit"
        class="form flex flex-row justify-start"
        :validation-schema="additionalSchema"
        v-slot="{ errors, isSubmitting }"
      >
        <Field
          type="number"
          min="0"
          class="input vehicle_form__input"
          :class="errors.additionalKms ? 'error' : ''"
          name="additionalKms"
          v-model="additionalKms"
          placeholder="Additional Kms"
        />
        <button
          type="submit"
          :disabled="isSubmitting"
          class="next_step_button vehicle_form__button"
        >
          Submit
        </button>
      </Form>
    </div>
  </div>
  <div class="mx-auto my-5 w-fit" role="status" v-if="!cars">
    <svg
      aria-hidden="true"
      class="fill-background dark:text-main mx-auto h-[70px] w-[70px] animate-spin text-gray-200"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    <span class="mt-[15px]">Loading cars...</span>
  </div>
  <div class="vehicles_wrapper mt-[-38px]" v-if="cars">
    <div class="vehicle" :key="car.class_id" v-for="car in cars">
      <div class="vehicle__slider_wrapper">
        <swiper
          :slides-per-view="1"
          :navigation="true"
          :space-between="32"
          :modules="modules"
          :speed="600"
          :pagination="{ clickable: true }"
          class="w-full max-w-[320px] md:max-w-full"
        >
          <swiper-slide :key="index" v-for="(image, index) in car.class_images.split('|')">
            <img :src="'https://form.trusty.host/cars/' + image" alt="" class="vehicle__image" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="vehicle__content">
        <div class="vehicle__name_wrapper">
          <p class="vehicle__name">{{ car.class_full_name }}</p>
          <p class="vehicle__price" v-if="isTour !== true && car.price">{{ car.price }} EUR</p>
          <p class="vehicle__price" v-if="isTour !== true && !car.price">
            The price will be calculated individually
          </p>
        </div>
        <div class="vehicle__capability_wrapper">
          <div class="vehicle__capability">
            <PassengersIcon v-if="!isMobile" />
            <PassengersMobileIcon v-if="isMobile" />
            <span class="vehicle__capability__text">Max {{ car.max_passengers }} People</span>
          </div>
          <div class="vehicle__capability">
            <SeatsIcon v-if="!isMobile" />
            <SeatsMobileIcon v-if="isMobile" />
            <span class="vehicle__capability__text">Max {{ car.max_luggage }} Suitcases</span>
          </div>
          <div class="vehicle__capability">
            <SeatsIcon v-if="!isMobile" />
            <SeatsMobileIcon v-if="isMobile" />
            <span class="vehicle__capability__text"
              >Max {{ car.max_hand_luggage }} hand luggage</span
            >
          </div>
        </div>
        <div class="vehicle__service_wrapper">
          <span class="vehicle__service__text">Free Wi-Fi</span>
          <span class="ellipse"></span>
          <span class="vehicle__service__text">Mineral water</span>
          <span class="ellipse"></span>
          <span class="vehicle__service__text">Includes Meet & Greet</span>
        </div>
        <p class="vehicle__description">
          Free 60 minutes wait time for airport pickups, 30 minutes for all others
        </p>
        <button class="next_step_button mt-6" @click="chooseCar(car)">Continue</button>
      </div>
    </div>
  </div>
</template>
<script setup>
// Import Swiper Vue.js components
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { onBeforeMount, onMounted, ref, inject, computed } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/compose/ismobile'
import * as yup from 'yup'

import { Form, Field } from 'vee-validate'
import { useCarsStore } from '@/stores/cars'
import { useOrderStore } from '@/stores/order'
import { useMainStore } from '@/stores/main'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import PassengersMobileIcon from '@/components/icons/PassengersMobileIcon.vue'
import PassengersIcon from '@/components/icons/PassengersIcon.vue'
import SeatsIcon from '@/components/icons/SeatsIcon.vue'
import SeatsMobileIcon from '@/components/icons/SeatsMobileIcon.vue'
import SummaryInfo from '@/components/SummaryInfo.vue'

const modules = [Pagination, Navigation]
const carsStore = useCarsStore()
const mainStore = useMainStore()
const orderStore = useOrderStore()
const { cars } = storeToRefs(carsStore)
const { orderData, orderId, tour } = storeToRefs(orderStore)
const { isRequesting } = storeToRefs(mainStore)
const { isMobile } = useMobile()

const isTour = computed(() => {
  return orderData.value.type_of_service === 'toursRoadshows'
})
const isHourly = computed(() => {
  return orderData.value.type_of_service === 'hourlyAsDirected'
})

const router = useRouter()
const axios = inject('axios')

let consultingMessage = ref('')
let additionalKms = ref(0)
let activeFormName = ref('notActive')

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    }
  }
  return obj
}

let backButtonUrl = ref('/')
let backButtonText = ref('Go Back')

if (
  (tour.value && orderData.value.redirectStep === 1) ||
  (isTour.value && orderData.value.redirectStep !== 1)
) {
  backButtonUrl.value = '/'
  backButtonText.value = 'Go Back'
} else {
  backButtonUrl.value = '/contact'
  backButtonText.value = 'Go Back to Contact'
}

const chooseCar = async (car) => {
  carsStore.selectCar(car)
  let data = {
    car: car.class_id,
    total: car.price,
    step: 2,
    status: 3
  }

  if (!isTour.value) {
    isRequesting.value = true

    orderData.value.total = data.total

    if (orderData.value.transaction_id) {
      axios
        .patch('/orders/' + orderId.value, data)
        .then(function (response) {
          console.log(response?.data)
          orderStore.update({
            car: data.car,
            total: data.total,
            step: data.step,
            status: 3
          })
          isRequesting.value = false
        })
        .catch(function (error) {
          console.log(error)
        })

      if (orderData.value.distance === null || orderData.value.distance === 0 || car.price === 0) {
        orderData.value.allowedPages['success'] = 1
        await router.push('/success')
      } else {
        await router.push('/payment/' + orderData.value.transaction_id)
      }
    } else {
      await axios
        .patch('/orders/' + orderId.value, data)
        .then(function (response) {
          orderStore.update({
            car: data.car,
            total: data.total,
            step: data.step,
            status: 3
          })
          console.log(response)
          if (response.data.data.distance === 0 || car.price === 0) {
            orderData.value.allowedPages['success'] = 1
            router.push('/success')
          } else {
            if (orderData.value.deal_id != null) {
              router.push('/payment/' + orderData.value.deal_id)
            } else {
              router.push('/payment/' + response.data.data.deal_id)
            }
          }
          isRequesting.value = false
        })
        .catch(function (error) {
          console.log(error)
          isRequesting.value = false
        })
    }
  } else {
    orderStore.update({
      car: data.car,
      total: data.total,
      step: data.step,
      status: 3
    })
    orderData.value.allowedPages['contact'] = 1
    await router.push('contact')
  }
}

const consultingSchema = yup.object({
  consultingMessage: yup.string().required().max(230)
})

const additionalSchema = yup.object({
  additionalKms: yup.number().required().min(0)
})

const consultingSubmit = () => {
  const data = {
    consulting: consultingMessage.value
  }
  if (orderId.value) {
    axios
      .post('/orders/update/orderConsulting/' + orderId.value, clean(data))
      .then(function () {
        orderData.value.allowedPages['success'] = 1
        router.push('/success')
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}
const additionalSubmit = () => {
  if (additionalKms.value === '') {
    orderStore.update({ extra_kms: 0 })
  } else {
    const data = {
      extra_kms: additionalKms.value,
      distance: orderData.value.distance + additionalKms.value
    }

    if (orderId.value) {
      isRequesting.value = true
      axios
        .post('/orders/update/orderAdditionalKms/' + orderId.value, data)
        .then(function (response) {
          console.log(response)
          if (response.data.data.cars !== undefined && response.data.data.cars != null) {
            let new_cars = response.data.data.cars
            carsStore.update(new_cars)
          }
          if (response.data.data.order !== undefined && response.data.data.order != null) {
            orderStore.update({
              extra_kms: additionalKms.value
            })
            orderStore.update({
              distance: orderData.value.distance + orderData.value.extra_kms
            })
          }
          isRequesting.value = false
        })
        .catch(function (error) {
          console.log(error)
          isRequesting.value = false
        })
    }
  }
}
const openConsultingForm = () => {
  activeFormName.value = 'ConsultingForm'
}
const openAdditionalKmsForm = () => {
  activeFormName.value = 'AdditionalKmsForm'
}

onBeforeMount(async () => {
  if (orderData.value.allowedPages.success_payment_intent) {
    orderStore.$reset()
    await router.push('forbidden')
  }

  if (
    orderData.value.utm_source != null &&
    orderData.value.redirectStep < 1 &&
    typeof orderData.value.redirectStep != 'undefined' &&
    tour.value
  ) {
    orderData.value.redirectStep = 1
  }

  if (!cars.value) {
    isRequesting.true = true
    await axios
      .post('cars/redirect', orderData.value)
      .then(function (response) {
        let data_cars = response.data.data.cars
        let data_order = response.data.data.order

        carsStore.update(data_cars)
        orderStore.updateOrder(data_order)
        orderStore.updateOrderId(data_order.id)

        if (response.data.data.order.distance !== null) {
          if (response.data.data.order.distance) {
            let distance_prev = response.data.data.order.distance
            let distance_rounded = Math.round(distance_prev / 10) * 10
            orderStore.update({ distance: distance_rounded })
          } else {
            orderStore.update({ distance: 0 })
          }
        }
        isRequesting.value = false
      })
      .catch(function (response) {
        console.log(response)
      })
  }

  if (!orderData.value.transaction_id && orderId.value) {
    isRequesting.value = true
    await axios

      .post('transaction/fetch/' + orderId.value)
      .then(function (response) {
        orderData.value.transaction_id = response.data.data.transaction_id
        isRequesting.value = false
      })
      .catch(function (error) {
        isRequesting.value = false
        console.log(error)
      })
  }
  // Clear Timer in Payment
  orderData.value.countdown = 'stop'
})
onMounted(async () => {})
</script>
