<template>
  <div :class="timerCss" style="margin-bottom: 0">
    <h1 :title="title">
      {{ formattedTime }}
    </h1>
  </div>
  <div>{{ title }}</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'

const { totalSeconds, timerCss, title } = defineProps({
  totalSeconds: {
    type: Number,
    required: true
  },
  timerCss: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  }
})

const isRunning = ref(false)
const startTime = ref(0)
const elapsedTime = ref(0)
const timerInterval = ref(null)

const formattedTime = computed(() => {
  let remainingTime = Math.max(totalSeconds - Math.floor(elapsedTime.value / 1000), 0)
  const hours = Math.floor(remainingTime / 3600)
  remainingTime %= 3600
  const minutes = Math.floor(remainingTime / 60)
  const seconds = remainingTime % 60

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
})

watch(totalSeconds, () => restartTimer())

onMounted(() => {
  const savedStartTime = localStorage.getItem('countdownTimerStartTime')
  const savedElapsedTime = localStorage.getItem('countdownTimerElapsedTime')
  if (savedStartTime && savedElapsedTime) {
    startTime.value = parseInt(savedStartTime)
    elapsedTime.value = parseInt(savedElapsedTime)
    resumeTimer()
  } else {
    startTimer()
  }
  window.addEventListener('beforeunload', handleBeforeUnload)
})

onBeforeUnmount(() => {
  saveState()
  window.removeEventListener('beforeunload', handleBeforeUnload)
})

const startTimer = () => {
  startTime.value = Date.now()
  isRunning.value = true
  setInterval(() => {
    elapsedTime.value = Date.now() - startTime.value
    const remainingTime = Math.max(totalSeconds - Math.floor(elapsedTime.value / 1000), 0)
    if (remainingTime <= 0) {
      clearInterval(timerInterval.value)
    }
  }, 1000)
}
const resumeTimer = () => {
  isRunning.value = true
  setInterval(() => {
    elapsedTime.value = Date.now() - startTime.value
    const remainingTime = Math.max(totalSeconds - Math.floor(elapsedTime.value / 1000), 0)
    if (remainingTime <= 0) {
      clearInterval(timerInterval.value)
    }
  }, 1000)
}

const saveState = () => {
  localStorage.setItem('countdownTimerStartTime', String(startTime.value))
  localStorage.setItem('countdownTimerElapsedTime', String(elapsedTime.value))
}
const restartTimer = () => {
  stopTimer()
  startTimer()
}
const stopTimer = () => {
  clearInterval(timerInterval.value)
  isRunning.value = false
}
const handleBeforeUnload = () => {
  saveState()
}
</script>

<style scoped></style>
