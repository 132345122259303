<template>
  <div :class="{ hidden: !menuShow }" class="menu">
    <div class="mx-auto flex w-full max-w-[1440px] flex-row items-center justify-between">
      <router-link to="/" v-if="not_allowed !== 'true'">
        <img :src="logos[mode]" class="logo" alt="" />
      </router-link>
      <a :href="projectLink" v-if="not_allowed === 'true'">
        <img :src="logos[mode]" class="logo" alt="" />
      </a>
      <div class="burger_wrapper ml-auto mr-[1px] gap-x-3">
        <div v-if="mode === 'dark'" @click="mainStore.update('light')" class="mode_toggler">
          <ModeLightIcon />
          <span class="text-[14px] leading-[142%] text-white sm:text-[20px] sm:leading-[22px]"
            >Light</span
          >
        </div>

        <div v-if="mode === 'light'" @click="mainStore.update('dark')" class="mode_toggler">
          <ModeDarkIcon />
          <span class="text-background text-[14px] leading-[142%] sm:text-[20px] sm:leading-[22px]"
            >Dark</span
          >
        </div>
        <router-link v-if="isLoggedIn" to="/account/accountinformation" class="button">
          My Account
        </router-link>
        <router-link v-if="!isLoggedIn" to="/account/signin" class="button"> Sign in </router-link>
      </div>
      <div class="menu_close" @click="toggleAccordion()">
        <TimesMobileIcon v-if="isMobile" />

        <TimesIcon v-if="!isMobile" />
      </div>
    </div>
    <div
      class="mx-auto mt-[110px] flex w-full max-w-[1440px] flex-col items-center justify-between md:flex-row xl:mt-[98px]"
    >
      <div
        class="menu_hover mb-[64px] flex w-full flex-col items-start justify-between xl:mb-0 xl:w-[65%]"
      >
        <a
          v-for="(item, index) in menuItems"
          :key="index"
          :href="item.url"
          :class="[activeIndex === index ? 'text-placeholder' : '', item.class]"
          @mousemove="handleMouseMove(index)"
          @mouseleave="handleMouseLeave"
        >
          {{ item.text }}
        </a>
      </div>
      <ContactInfo />
    </div>
  </div>
  <div class="flex w-full flex-row items-center justify-between p-4 sm:p-0">
    <router-link to="/" v-if="not_allowed != 'true'" class="sm:w-[300px]">
      <img :src="logos[mode]" class="logo" alt="" />
    </router-link>
    <a :href="projectLink" v-if="not_allowed == 'true'" class="sm:w-[300px]">
      <img :src="logos[mode]" class="logo" alt="" />
    </a>
    <nav class="hidden flex-row items-center justify-between xl:flex">
      <a
        :key="index"
        v-for="(link, index) in links"
        target="_blank"
        :href="link.url"
        class="menu__item mr-[48px]"
        >{{ link.title }}</a
      >
    </nav>

    <div class="burger_wrapper gap-x-3">
      <div v-if="mode === 'dark'" @click="mainStore.update('light')" class="mode_toggler">
        <ModeLightIcon />
        <span class="text-[14px] leading-[142%] text-white sm:text-[20px] sm:leading-[22px]"
          >Light</span
        >
      </div>
      <div v-if="mode === 'light'" @click="mainStore.update('dark')" class="mode_toggler">
        <ModeDarkIcon />
        <span class="text-background text-[14px] leading-[142%] sm:text-[20px] sm:leading-[22px]"
          >Dark</span
        >
      </div>
      <router-link v-if="isLoggedIn" to="/account/accountinformation" class="button">
        My Account
      </router-link>
      <router-link v-if="!isLoggedIn" to="/account/signin" class="button"> Sign in </router-link>
      <BurgerIcon @click="toggleAccordion()" />
    </div>
  </div>
</template>
<script setup>
import darklogo from '~project_assets/images/logo.png'
import lightlogo from '~project_assets/images/logo-light.png'
import { ref, reactive, inject, onBeforeMount } from 'vue'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/compose/ismobile'
import ModeLightIcon from '@/components/icons/ModeLightIcon.vue'
import ModeDarkIcon from '@/components/icons/ModeDarkIcon.vue'
import ContactInfo from '@/blocks/ContactInfo.vue'
import BurgerIcon from '@/components/icons/BurgerIcon.vue'
import TimesMobileIcon from '@/components/icons/TimesMobileIcon.vue'
import TimesIcon from '@/components/icons/TimesIcon.vue'
import { useUserStore } from '@/stores/user'

const mainStore = useMainStore()
const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)
const storage = inject('storage')

const projectLink = ref(import.meta.env.VITE_PROJECT_URL)

const { mode } = storeToRefs(mainStore)

let not_allowed = storage.getItem('not_allowed', 'session')

let menuShow = ref(false)
let logos = reactive({
  light: lightlogo,
  dark: darklogo
})

const { isMobile } = useMobile()

const toggleAccordion = () => {
  if (menuShow.value === true) {
    document.body.classList.remove('overflow-hidden')
    document.body.classList.add('overflow-auto')
  } else {
    document.body.classList.remove('overflow-auto')
    document.body.classList.add('overflow-hidden')
  }
  menuShow.value = !menuShow.value
}

const activeIndex = ref(null)

const handleMouseMove = (index) => {
  activeIndex.value = index
}

const handleMouseLeave = () => {
  activeIndex.value = null
}

const links = []
const menuItems = []

onBeforeMount(() => {
  if (import.meta.env.VITE_PROJECT_ALIAS === 'dli') {
    links.push({
      title: 'About Us',
      url: projectLink.value + 'about-us/'
    })
    links.push({
      title: 'Limo Service',
      url: projectLink.value + 'services/limo-service/'
    })
    links.push({
      title: 'Italy Tours',
      url: projectLink.value + 'italy-tours/'
    })
    links.push({
      title: 'B2B',
      url: projectLink.value + 'b2b/'
    })

    menuItems.push({
      text: 'Fleet',
      url: projectLink.value + 'fleet/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Airport Transfers',
      url: projectLink.value + 'services/airport-transfers/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Gems of Italy',
      url: projectLink.value + 'blog/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Contact Us',
      url: projectLink.value + 'contact/',
      class: 'menu_title mt-0'
    })
  }

  if (import.meta.env.VITE_PROJECT_ALIAS === 'rlt') {
    links.push({
      title: 'About Us',
      url: projectLink.value + 'about-us/'
    })
    links.push({
      title: 'Limo Service',
      url: projectLink.value + 'rome-limousine-service/'
    })
    links.push({
      title: 'Chauffeur Service',
      url: projectLink.value + 'rome-chauffeur-service/'
    })
    links.push({
      title: 'Private Driver',
      url: projectLink.value + 'rome-private-driver/'
    })

    menuItems.push({
      text: 'Fleet',
      url: projectLink.value + 'fleet/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Rome Car Service',
      url: projectLink.value + 'rome-car-service/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'B2B',
      url: projectLink.value + 'b2b/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Contact Us',
      url: projectLink.value + 'contact/',
      class: 'menu_title mt-0'
    })
  }

  if (import.meta.env.VITE_PROJECT_ALIAS === 'dgt') {
    links.push({
      title: 'About Us',
      url: projectLink.value + 'about-us/'
    })
    links.push({
      title: 'Private tours',
      url: projectLink.value + 'tuscany-private-tours/'
    })
    links.push({
      title: 'Chauffeur Service',
      url: projectLink.value + 'tuscany-chauffeur-service/'
    })
    links.push({
      title: 'B2B',
      url: projectLink.value + 'b2b/'
    })

    menuItems.push({
      text: 'Fleet',
      url: projectLink.value + 'our-fleet/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Airport Transfers',
      url: projectLink.value + 'transfers/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
    menuItems.push({
      text: 'Contact Us',
      url: projectLink.value + 'contacts/',
      class: 'menu_title mt-0 pb-6 xl:pb-[48px]'
    })
  }
})
</script>
