import { defineStore } from 'pinia'
import { ref } from 'vue'

const STORE_NAME = 'googleAddress'
const LOCALSTORAGE_KEY = 'googleAddress'

export const useGoogleAddressStore = defineStore(STORE_NAME, () => {
  const fields = ref({
    pickup: {
      name: 'pickup',
      placeholder: 'Pick Up Location*',
      valid: false
    },
    dropoff: {
      name: 'dropoff',
      placeholder: 'Drop off Location*',
      valid: false
    }
  })
  const pathStartFinish = ref({})
  const activeAuto = ref('')
  function $reset() {
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
  }

  function updatePickupDropOff(latLng, point) {
    if (point === 'pickup') {
      pathStartFinish.value.pickup = latLng
    } else if (point === 'dropoff') {
      pathStartFinish.value.dropoff = latLng
    }
  }
  const getFieldsWay = (type) => {
    switch (type) {
      //For the summary fields are valid for picked in first step
      case 'summary': {
        return {
          pickup: {
            name: 'pickup',
            placeholder: 'Pick Up Location*',
            valid: true
          },
          dropoff: {
            name: 'dropoff',
            placeholder: 'Drop off Location*',
            valid: true
          }
        }
      }
      //One way transfer has twofields
      case 'oneWayTransfer': {
        return {
          pickup: {
            name: 'pickup',
            placeholder: 'Pick Up Location*',
            valid: false
          },
          dropoff: {
            name: 'dropoff',
            placeholder: 'Drop off Location*',
            valid: false
          }
        }
      }
      //Other types of transfer
      default: {
        return {
          pickup: {
            name: 'pickup',
            placeholder: 'Pick Up Location*',
            valid: false
          }
        }
      }
    }
  }

  return { fields, activeAuto, pathStartFinish, $reset, getFieldsWay, updatePickupDropOff }
})
