import { defineStore } from 'pinia'
import { useFetcher } from '@/compose/axios'
import { jwtDecode } from 'jwt-decode'

const STORE_NAME = 'centrifugo'
const LOCALSTORAGE_KEY = 'centrifugo'

export const useCentrifugoStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })

  const refreshToken = async () => {
    await axiosInstance.post('/centrifuge/refresh-token').then(function (response) {
      sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(response.data.data.token))
    })
  }

  const getToken = async () => {
    let data = sessionStorage.getItem(LOCALSTORAGE_KEY)

    if (data === null) {
      await refreshToken()
      data = sessionStorage.getItem(LOCALSTORAGE_KEY)
    }

    let token = JSON.parse(data)

    const decoded = jwtDecode(token)

    if (decoded.exp < (new Date().getTime() + 1) / 1000) {
      await refreshToken()
      data = sessionStorage.getItem(LOCALSTORAGE_KEY)
    }

    return JSON.parse(data)
  }

  return { refreshToken, getToken }
})
