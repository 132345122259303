<template>
  <div
    class="font-['Basier Circle'] w-full flex-col items-start justify-start gap-10 pt-10 font-normal text-white md:inline-flex"
  >
    <div
      v-if="isShowModal"
      @click="closeModal"
      class="defaultModal fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-70 p-4"
    >
      <div @click.stop class="wrapper h-max w-full max-w-2xl rounded-lg">
        <div class="flex justify-end p-4">
          <button
            @click="closeModal"
            aria-label="close"
            class="closeButton inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400"
            type="button"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div class="p-6 pt-0">
          <p>Main Passenger is required field</p>
        </div>
        <div class="border-t border-gray-600 p-6">
          <button
            @click="closeModal"
            type="button"
            class="bg-main text-background rounded-[37px] px-5 py-2.5 text-center"
          >
            Ok
          </button>
        </div>
      </div>
    </div>

    <div class="data_header px-4 sm:px-0">Service data</div>
    <div class="data_description px-4 sm:px-0">
      You are over 90% through the process. The below data is specifically related to the specific
      service.
    </div>
    <div class="mb-6 inline-flex w-full items-center justify-between px-4 sm:px-0 md:w-[486px]">
      <div class="relative h-2 w-4/5 md:w-[415px]">
        <div class="absolute left-0 top-0 h-2 w-full rounded-[99px] bg-[#3D4043]"></div>
        <div
          class="dgt-theme rlt-theme bg-main absolute left-0 top-0 h-2 w-[90%] rounded-[99px]"
        ></div>
      </div>
      <div
        class="dgt-theme-progress rlt-theme-progress text-main w-[19%] text-center font-bold leading-snug md:text-xl"
      >
        90 %
      </div>
    </div>
    <div
      class="flex flex-col items-start justify-start gap-6 self-stretch rounded-[40px] bg-white p-4 dark:bg-[#3D4043] sm:p-8"
    >
      <div class="text-background text-base leading-relaxed dark:text-white md:text-2xl">
        Service data
      </div>
      <form action="" class="w-full">
        <div class="md:flex md:gap-4">
          <div class="flex w-full flex-col md:w-auto">
            <input
              v-model="profile.number_of_passengers"
              @keypress="
                (e) => {
                  // only numbers
                  if (e.charCode >= 48 && e.charCode <= 57) {
                    return true
                  } else {
                    e.preventDefault()
                  }
                }
              "
              @input="
                (e) => {
                  if (profile.number_of_passengers == '') {
                    profile.number_of_passengers = 1
                  }
                }
              "
              type="number"
              min="1"
              placeholder="1"
              class="text-background placeholder:text-background dark:bg-background mb-2 rounded-[53px] border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none dark:border-[#3D4043] dark:text-white dark:placeholder:text-white md:text-lg"
            />
            <span class="text-xs text-[#878787] dark:text-[#C8C8C8] md:text-sm"
              >Number of passengers</span
            >
          </div>
          <div class="flex w-full flex-col md:grow">
            <input
              v-model="profile.main_passenger"
              type="text"
              placeholder="Passengers Name/Data"
              class="text-background placeholder:text-background dark:bg-background mb-2 mt-4 rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] outline-none dark:border-[#3D4043] dark:text-white dark:placeholder:text-white md:mt-0 md:text-lg"
            />
            <span class="text-xs text-[#878787] dark:text-[#C8C8C8] md:text-sm"
              >If you are not the passenger yourself,<br />please indicate the name(s) of the lead
              passenger(s)</span
            >
          </div>
          <div class="mb-4 flex w-full flex-col md:grow">
            <select
              v-model="profile.other_language"
              name="select"
              class="text-background placeholder:text-background dark:bg-background mb-2 mt-4 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[17px] py-5 text-sm tracking-[-0.28px] outline-none dark:border-[#3D4043] dark:text-white dark:placeholder:text-white md:mt-0 md:w-auto md:text-lg"
            >
              <option value="English">English</option>
              <option value="Italian">Italian</option>
              <option value="Spanish">Spanish</option>
            </select>
            <span class="text-xs text-[#878787] dark:text-[#C8C8C8] md:text-sm"
              >In our company all our chauffeurs are experienced and speak English if you prefer a
              different language please indicate it</span
            >
          </div>
        </div>
        <div>
          <div class="md:flex md:gap-4">
            <div class="mb-4 flex w-full flex-col">
              <input
                v-model="profile.pickup_specific"
                type="text"
                placeholder="Pick Up Location Specifics"
                class="text-background placeholder:text-background dark:bg-background mb-2 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] dark:border-[#3D4043] dark:text-white dark:placeholder:text-white md:text-lg"
              />
              <span class="text-xs text-[#878787] dark:text-[#C8C8C8] md:text-sm"
                >If applicable please indicate arrival Flight/Train Number of GPS coordinates if
                needed (such as for Villas in the countriside or difficult to reach
                locatitons)</span
              >
            </div>
            <div class="mb-4 flex w-full flex-col">
              <input
                v-model="profile.dropoff_specific"
                type="text"
                placeholder="Drop Off Location Specifics"
                class="text-background placeholder:text-background dark:bg-background mb-2 w-full rounded-[53px] border border-[#878787] bg-[#E8EDE8]/50 px-[22px] py-4 text-sm tracking-[-0.28px] dark:border-[#3D4043] dark:text-white dark:placeholder:text-white md:text-lg"
              />
              <span class="text-xs text-[#878787] dark:text-[#C8C8C8] md:text-sm"
                >If applicable please indicate departure Flight/Train Number of GPS coordinates if
                needed (such as for Villas in the countriside or difficult to reach
                locatitons)</span
              >
            </div>
          </div>
          <div class="mb-4 mt-10">
            <span class="text-background text-base leading-4 dark:text-white md:text-xl"
              >If we ran in shortage of availability for the model of vehile you selected,<br />
              would you mind if we dispatched a superior model as a complimentary upgrade?</span
            >
            <div class="mt-6 flex flex-col gap-5 md:flex-row md:gap-14">
              <div class="flex flex-col gap-5">
                <div class="flex items-center">
                  <input
                    v-model="profile.change_vehicle"
                    checked
                    id="default-radio-5"
                    type="radio"
                    value="No"
                    name="default-radio"
                    class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border-2 border-[#898989] bg-transparent"
                  />
                  <label
                    for="default-radio-5"
                    class="text-background ms-2 text-sm dark:text-white md:text-xl"
                    >No</label
                  >
                </div>
                <div class="flex items-center">
                  <input
                    v-model="profile.change_vehicle"
                    id="default-radio-3"
                    type="radio"
                    value="Please advise me, before you dispatch a different vehicle"
                    name="default-radio"
                    class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border-2 border-[#898989] bg-transparent"
                  />
                  <label
                    for="default-radio-3"
                    class="text-background ms-2 text-sm dark:text-white md:text-xl"
                    >Please advise me, before you dispatch a different vehicle</label
                  >
                </div>
              </div>
              <div class="flex flex-col gap-5">
                <div class="flex items-center">
                  <input
                    v-model="profile.change_vehicle"
                    id="default-radio-4"
                    type="radio"
                    value="Yes, I only want the vehicles I selected"
                    name="default-radio"
                    class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border-2 border-[#898989] bg-transparent"
                  />
                  <label
                    for="default-radio-4"
                    class="text-background ms-2 text-sm dark:text-white md:text-xl"
                    >Yes, I only want the vehicles I selected</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form action="" class="w-full">
        <div class="my-2 md:my-4 md:flex md:content-center">
          <span
            class="text-background text-base leading-4 tracking-[-0.32px] dark:text-white md:text-xl"
            >Would you enjoy being notified by email with Driver Contact Data before the
            service?</span
          >
          <div class="mt-5 flex flex-col gap-8 md:ml-10 md:mt-0 md:flex-row">
            <div class="flex items-center">
              <input
                v-model="profile.contact_before_service"
                checked
                id="default-radio-9"
                type="radio"
                value="Yes"
                name="default-radio"
                class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border-2 border-[#898989] bg-transparent"
              />
              <label
                for="default-radio-9"
                class="text-background ms-2 text-sm font-medium dark:text-white md:text-xl"
                >Yes</label
              >
            </div>
            <div class="flex items-center">
              <input
                v-model="profile.contact_before_service"
                id="default-radio-8"
                type="radio"
                value="No"
                name="default-radio"
                class="radio-dgt radio-rlt text-main h-6 w-6 cursor-pointer border-2 border-[#898989] bg-transparent"
              />
              <label
                for="default-radio-8"
                class="text-background ms-2 text-sm font-medium dark:text-white md:text-xl"
                >No</label
              >
            </div>
          </div>
        </div>
      </form>
      <div
        class="dgt-theme-btn rlt-theme-btn bg-main inline-flex h-16 w-full cursor-pointer items-center justify-center gap-2.5 rounded-[37px] px-4 py-3"
        @click="submitProfile"
      >
        <div
          class="text-background cursor-default text-base font-semibold leading-tight md:text-lg"
        >
          Submit
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useProfileCompletionStore } from '@/stores/profile_completion'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

const orderStore = useOrderStore()
const profileCompletionStore = useProfileCompletionStore()
const router = useRouter()
const { profile, fromServiceData } = storeToRefs(profileCompletionStore)
const { orderData } = storeToRefs(orderStore)

const isShowModal = ref(false)

function closeModal() {
  isShowModal.value = false
}

const submitProfile = () => {
  fromServiceData.value = true
  orderData.value.allowedPages['success'] = 1
  profileCompletionStore.profileCompletion(orderData.value.deal_id)
  router.push('/success')
}
</script>
