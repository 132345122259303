<template>
  <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="fixed inset-0 bg-black opacity-50" @click="closePopUp"></div>
    <div
      class="relative z-10 w-full max-w-xl rounded-[35px] bg-white p-8 text-white shadow-lg dark:bg-[#272729]"
    >
      <h1 class="title mb-4 text-6xl font-bold dark:text-white">Sign Up</h1>
      <p class="mb-6 text-black dark:text-white">
        To book additional rides more efficiently and quickly, it's best to register. It only takes
        a few seconds and will enhance your experience on our site.
      </p>
      <form @submit.prevent="submitForm">
        <div class="mb-4 flex gap-4">
          <label for="" class="w-full text-gray-600 dark:text-[#878787]">
            First Name
            <input
              type="text"
              class="input ym-record-keys w-full"
              placeholder="First Name"
              v-model="firstName"
              required
            />
          </label>
          <label for="" class="w-full text-gray-600 dark:text-[#878787]">
            Last Name
            <input
              type="text"
              class="input ym-record-keys w-full"
              placeholder="Last Name"
              v-model="lastName"
              required
            />
          </label>
        </div>
        <div class="mb-4">
          <label for="" class="w-full text-gray-600 dark:text-[#878787]">
            E-mail Address
            <input
              type="email"
              class="input ym-record-keys w-full"
              placeholder="E-mail Address"
              v-model="email"
              required
            />
          </label>
        </div>
        <div class="mb-4">
          <label for="" class="w-full text-gray-600 dark:text-[#878787]">
            Mobile Phone Number
            <vue-tel-input
              v-model="phone"
              maxlength="230"
              @country-changed="countryChanged"
              v-on:beforeinput="utils.isNumber($event)"
              class="input ym-record-keys m-0 w-full"
              :dropdownOptions="{
                showFlags: true,
                showDialCodeInList: true,
                showDialCodeInSelection: true
              }"
              v-bind="bindProps"
            ></vue-tel-input>
          </label>
        </div>
        <button type="submit" class="button w-full">Sign Up</button>
      </form>
      <p class="mt-4 text-center text-black dark:text-white">
        If you already have an account, please
        <button @click="handleSignInClick" type="button" class="text-green-400">
          sign up here
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const phone = ref('')

    const closePopUp = () => {
      emit('close')
    }

    const submitForm = () => {
      // Handle form submission
    }

    const handleSignInClick = () => {
      closePopUp()
      emit('open-signin')
    }

    return {
      firstName,
      lastName,
      email,
      phone,
      closePopUp,
      submitForm,
      handleSignInClick
    }
  }
}
</script>

<style scoped>
/* Scoped styles if necessary */
</style>
