<template>
  <h1 class="title px-4 sm:p-0" v-if="flow !== 'mainsite'">Car Service<br />with Private Driver</h1>
  <div class="contact_form_wrapper !mt-6 px-4 pt-6 md:!mt-[48px]">
    <div class="w-full">
      <span class="summary_title home_form__title">Choose Type of Service</span>
      <div v-if="isMobile" class="relative w-full overflow-x-auto pb-[2px]">
        <div class="home_form__toggler w-max">
          <button
            @click="changeForm(button, true)"
            v-for="(button, index) in buttons"
            :key="index"
            :class="{ ...button.class, active: button.active }"
          >
            {{ button.name }}
          </button>
        </div>
      </div>
      <div v-else class="home_form__toggler">
        <button
          @click="changeForm(button, true)"
          v-for="(button, index) in buttons"
          :key="index"
          :class="{ ...button.class, active: button.active }"
        >
          {{ button.name }}
        </button>
      </div>
      <OneWayTransferForm v-if="formActive === 1" />
      <HourlyAsDirectedForm v-if="formActive === 2" />
      <ToursForm v-if="formActive === 3" />
      <!-- <label class="relative inline-flex items-center cursor-pointer mt-[28px] w-full">
  <input type="checkbox" value="" class="sr-only peer">
  <div class="w-[39px] h-6 md:w-[51px] md:h-[31px] bg-toggler_gray peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-[80%] md:peer-checked:after:translate-x-[70%] peer-checked:after:border-white peer-checked:after:bg-white peer-checked:bg-toggler_gray after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 md:after:h-[27px] md:after:w-[27px] after:transition-all peer-checked:bg-blue-600"></div>
  <span class="account_toggler_text md:text-black">Create Personal Account</span>
</label> -->
      <p
        v-if="flow !== 'mainsite'"
        class="privacy_text home_form__text mt-4 pb-[15px] text-center sm:hidden"
      >
        For multiple rides/days service <a href="" class="text-main">click here</a>.<br />For Agency
        requests add your IATA or License Code in the notes
      </p>
    </div>
  </div>
  <p
    v-if="flow !== 'mainsite'"
    class="privacy_text home_form__text mt-[118px] hidden text-center sm:block"
  >
    For multiple rides/days service <a href="" class="text-main">click here</a>.<br />For Agency
    requests add your IATA or License Code in the notes
  </p>
</template>
<script setup>
import { reactive, ref, onBeforeMount } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
import OneWayTransferForm from '@/components/forms/OneWayTransferForm.vue'
import HourlyAsDirectedForm from '@/components/forms/HourlyAsDirectedForm.vue'
import ToursForm from '@/components/forms/ToursForm.vue'
import { useMainStore } from '@/stores/main'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/compose/ismobile'

const { isMobile } = useMobile()
const orderStore = useOrderStore()

const mainStore = useMainStore()
const { flow } = storeToRefs(mainStore)
const buttons = reactive([
  {
    class: { 'home_form__button button mr-2': true },
    active: true,
    name: 'One Way Transfer',
    index: 1
  },
  {
    class: { 'home_form__button button mr-2': true },
    active: false,
    name: 'Hourly as directed',
    index: 2
  }
])

if (import.meta.env.VITE_PROJECT_ALIAS !== 'rlt') {
  buttons.push({
    class: { 'home_form__button button': true },
    active: false,
    name: 'Tours/Roadshows',
    index: 3
  })
}

const formActive = ref(1)
const changeForm = (button) => {
  if (!button.active) {
    buttons.forEach((el) => {
      el.active = false
    })
    button.active = true
    formActive.value = button.index
  }
}

onBeforeMount(async () => {
  // Clear Timer in Payment
  orderStore.orderData.timer_expires ? orderStore.$reset() : ''
  orderStore.update({
    countdown: 'stop'
  })
})
</script>
