import { defineStore } from 'pinia'
import { useFetcher } from '@/compose/axios'
import { ref } from 'vue'

const STORE_NAME = 'rides-history'
const LOCALSTORAGE_KEY = 'rides-history'

export const useRidesHistoryStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
  const rides = ref([])
  const isRidesFetched = ref(false)
  const isNoRides = ref(false)
  const isRequestPending = ref(false)
  const isRequestRefused = ref(false)
  const isRequestSuccessful = ref(false)
  const isRequestMessage = ref(true)
  const requestSent = ref(false)

  const loadRides = () => {
    let localRides = JSON.parse(sessionStorage.getItem(LOCALSTORAGE_KEY))

    if (localRides === null) {
      axiosInstance.get('/rides/histories').then((response) => updateRides(response.data))
    } else {
      rides.value = localRides
      isRidesFetched.value = true
    }
  }

  const updateRides = (newRides) => {
    if (newRides.data.length === 0) {
      rides.value = []
      isRidesFetched.value = false
    } else {
      rides.value = newRides.data
      isRidesFetched.value = true
      sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(rides.value))
      setTimeout(
        function () {
          sessionStorage.removeItem(LOCALSTORAGE_KEY)
        },
        10 * 60 * 1000
      )
    }
  }

  const sendRequestBtn = () => {
    if (isRequestMessage.value && !checkRequestSentOrNot()) {
      axiosInstance.post('/rides/histories/request').then(() => {
        isRequestMessage.value = false
        isRequestPending.value = true
        isRequestRefused.value = false
        isRequestSuccessful.value = false
      })
      sessionStorage.setItem(`request-${LOCALSTORAGE_KEY}`, JSON.stringify({ status: 'pending' }))
      axiosInstance.get('/rides/histories/accept-time').then((response) => {
        sessionStorage.setItem(
          `timer-${LOCALSTORAGE_KEY}`,
          JSON.stringify({ time: response.data.data.avg_time_seconds })
        )
      })
    }
  }

  const getSpendTime = async () => {
    const key = `timer-${LOCALSTORAGE_KEY}`

    let timer = sessionStorage.getItem(key)

    if (timer === null) {
      await axiosInstance.get('/rides/histories/accept-time').then((response) => {
        const time = JSON.stringify({ time: response.data.avg_time_seconds })

        if (time !== null) {
          sessionStorage.setItem(key, time)
          return time
        }

        return 5 * 60
      })
    }

    return JSON.parse(sessionStorage.getItem(key))?.time ?? 5 * 60
  }

  const updateRequestStatus = (status) => {
    const key = `request-${LOCALSTORAGE_KEY}`
    sessionStorage.setItem(key, JSON.stringify({ status: status }))

    // blocks user for 1 hour to sending new requests
    if (status === 'refused') {
      setTimeout(
        function () {
          sessionStorage.removeItem(LOCALSTORAGE_KEY)
        },
        60 * 60 * 1000
      )
    }

    if (status === 'empty') {
      setTimeout(
        function () {
          sessionStorage.removeItem(LOCALSTORAGE_KEY)
        },
        2 * 3600 * 1000
      )
    }
  }

  const checkRequestSentOrNot = () => {
    const storageKey = `request-${LOCALSTORAGE_KEY}`
    const data = JSON.parse(sessionStorage.getItem(storageKey))

    if (data !== null) {
      requestSent.value = true

      isRequestMessage.value = false
      isRequestPending.value = false
      isRequestRefused.value = false
      isRequestSuccessful.value = false
      isNoRides.value = false

      isRequestPending.value = data.status === 'pending'
      isRequestRefused.value = data.status === 'refused'
      isRequestSuccessful.value = data.status === 'succeed'
      isNoRides.value = data.status === 'empty'

      return true
    }

    return false
  }

  return {
    rides,
    isRidesFetched,
    isNoRides,
    isRequestPending,
    isRequestRefused,
    isRequestSuccessful,
    isRequestMessage,
    requestSent,
    checkRequestSentOrNot,
    loadRides,
    updateRides,
    sendRequestBtn,
    updateRequestStatus,
    getSpendTime
  }
})
